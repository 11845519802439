import { TOASTER_KEY } from './shared/helpers/constants';
import {
  ChangeDetectionStrategy,
  Component,
  effect,
  inject,
  ViewEncapsulation,
} from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import { UserService } from './core/services/user/user.service';
import { ToastModule } from 'primeng/toast';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, ToastModule ],
  templateUrl: './app.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None

})
export class AppComponent {
  title = 'TruckTrail';
  private readonly userService = inject(UserService);
  private readonly router = inject(Router);
  userExists = this.userService.userExists;
  toasterKey = TOASTER_KEY;

  constructor() {
    effect(() => {
      if (!this.userExists()) {
        this.router.navigate(['/']);
      }
    });
  }
}
