import { Routes } from '@angular/router';
import { authenticatedGuard, alreadyAuthenticatedGuard } from './core/guards/auth/auth.guard';

export const routes: Routes = [
    {
        path: '',
        canActivate: [alreadyAuthenticatedGuard],
        children: [
            {
                path: '',
                loadComponent: () => import('./core/auth/auth.component').then(c => c.AuthComponent),
            },
            {
                path: 'forgot-password',
                loadComponent: () => import('./features/auth/forgot-password/forgot-password.component').then(c => c.ForgotPasswordComponent),
            },
            {
                path: 'email-sent-confirmation',
                loadComponent: () => import('./features/auth/email-sent-confirmation/email-sent-confirmation.component').then(c => c.EmailSentConfirmationComponent),
            },
            {
                path: 'reset-password',
                loadComponent: () => import('./features/auth/reset-password/reset-password.component').then(c => c.ResetPasswordComponent),
            },
            {
                path: 'reset-password-success',
                loadComponent: () => import('./features/auth/reset-password-success/reset-password-success.component').then(c => c.ResetPasswordSuccessComponent),
            },
        ]
    },
    {
        path: 'dispatcher',
        loadChildren: () =>
            import('./features/dispatcher/dispatcher.routes').then(
                (m) => m.dispatcherRoutes
            ),
        canActivate: [authenticatedGuard]
    }
];
