import { APP_INITIALIZER, ApplicationConfig, provideZoneChangeDetection } from '@angular/core';
import { provideRouter, withPreloading } from '@angular/router';

import { routes } from './app.routes';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { provideAngularQuery, QueryClient } from '@tanstack/angular-query-experimental';
import { authInterceptor } from './core/interceptors/auth/auth.interceptor';
import { UserService } from './core/services/user/user.service';
import { MessageService } from 'primeng/api';
import { FlagBasedPreloadStrategyService } from './core/services/preload/flag-based-preload-strategy.service';

function initializeApp(userService: UserService) {
  return () => userService.fetchUser();
}
export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(routes, withPreloading(FlagBasedPreloadStrategyService)),
    provideAnimationsAsync(),
    provideHttpClient(withInterceptors([authInterceptor])),
    provideAngularQuery(
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false,
          },
        },
      }),
  ),
  {
    provide: APP_INITIALIZER,
    useFactory: initializeApp,
    multi: true,
    deps: [UserService],
  },
  MessageService, provideAnimationsAsync()
  ],
};
