import { HttpErrorResponse, HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { catchError, throwError } from 'rxjs';
import { AuthService } from '../../services/auth/auth.service';
import { UserService } from '../../services/user/user.service';
import { IS_PUBLIC_API } from '../../../shared/helpers/lib';
import { Error } from '../../../shared/helpers/types';
import { MessageService } from 'primeng/api';
import { TOASTER_KEY } from '../../../shared/helpers/constants';

export const authInterceptor: HttpInterceptorFn = (req, next) => {
  const authService = inject(AuthService);
  const userService = inject(UserService);
  const messageService = inject(MessageService);
  const token = authService.getToken();

  if (!req.context.get(IS_PUBLIC_API)) {
    if (token) {
      req = req.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`,
          'x-tt-tenant-identifier': userService.currentTenantId() || '',
        },
      });
    }
  }
  return next(req).pipe(
    catchError((error: HttpErrorResponse) => {
      if (error.status === 403) {
        const authData = authService.getAuthData();
        if (!authData) return throwError(() => error);
        return authService.refreshAuthentication(req, next, {
          accessToken: authData.accessToken,
          refreshToken: authData.refreshToken,
        });
      } else if (error.status === 401) {
        authService.logout();
        return throwError(() => error);
      } else if (error.status === 404) {
          return throwError(() => error);
      } else {
        const errors = error.error.errors as Error[];

        errors.forEach((error) => {
          messageService.add({
            key: TOASTER_KEY,
            data: {
              message: error.description,
              error: true,
            },
          });
        });

        return throwError(() => error);
      }
    })
  );
};
